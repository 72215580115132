import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  line-height: 40px;

  @media (max-width: 575.98px) {
    padding: 0 32px;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 400px;
  }
`;

export const CardContainer = styled.main`
  margin: 24px 0;
  row-gap: 8px;
`;

export const Text = styled.div`
  max-width: 216px;

  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.34px;
  color: #6f6f6f;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
`;
