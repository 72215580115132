import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IContractDetails from 'model/contract-details';

interface IState {
  contracDetails?: IContractDetails;
}

const initialState: IState = {
  contracDetails: undefined,
};

const contracDetailsSlice = createSlice({
  name: '@@contracDetails',
  initialState,
  reducers: {
    addContracDetails(state, action: PayloadAction<IContractDetails>) {
      state.contracDetails = action.payload;
    },
    resetContracDetails(state) {
      state.contracDetails = undefined;
    },
  },
});

export const { addContracDetails, resetContracDetails } = contracDetailsSlice.actions;

export default contracDetailsSlice.reducer;
