import { AxiosRequestConfig, AxiosResponse } from 'axios';
import ICompany from 'model/company';
import IDocument, { ITypeContractsFilter } from 'model/document';
import { PageableResponse, PaginationParameters } from 'model/pageable';
import { buildUrl } from 'shared/util/api-utils';
import { api } from './api';
import { TypeBatchSubscription } from 'model/type-batch-subscription';
import SignatureMethods from 'model/enums/signature-methods';
import { ISubscriptionData } from 'model/contract-details';

const companyApi = () => {
  const getAllCompanies = (): Promise<AxiosResponse<ICompany[]>> => {
    return api.get<ICompany[]>('/company/current-user');
  };

  const getAllDocumentsPendingSignature = (
    uuid: string,
    pagination: PaginationParameters
  ): Promise<AxiosResponse<PageableResponse<IDocument>>> => {
    const url = buildUrl(`/documents/company/${uuid}/pending`, pagination);
    return api.get<PageableResponse<IDocument>>(url);
  };

  const getDocumentSignatureHistory = (
    uuid: string,
    pagination: PaginationParameters
  ): Promise<AxiosResponse<PageableResponse<IDocument>>> => {
    const url = buildUrl(`/documents/company/${uuid}/history`, pagination);
    return api.get<PageableResponse<IDocument>>(url);
  };

  const sendBatchSignatureTokenToEmail = (subscriptionData: TypeBatchSubscription): Promise<AxiosResponse<SignatureMethods[]>> => {
    return api.post<SignatureMethods[]>('/token-signatures/batch', subscriptionData);
  };

  const signBatchContract = (ssubscriptionData: ISubscriptionData): Promise<AxiosResponse<ISubscriptionData>> => {
    return api.patch<ISubscriptionData>('/person-signature/confirm-data-group', ssubscriptionData);
  };

  return {
    getAllCompanies,
    getAllDocumentsPendingSignature,
    getDocumentSignatureHistory,
    sendBatchSignatureTokenToEmail,
    signBatchContract,
  };
};

export default companyApi();
