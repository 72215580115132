import { StyledContainer, StyledInnerContainer, StyledTextContainer, StyledTitle } from './styles';

interface IProps {
  icon: JSX.Element;
  title: string;
  text: string;
}

const InstructionCard = ({ icon, title, text }: IProps) => {
  return (
    <StyledContainer>
      <StyledInnerContainer>
        {icon}
        <StyledTextContainer>
          <StyledTitle>{title}</StyledTitle>
          {text}
        </StyledTextContainer>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default InstructionCard;
