import styled from 'styled-components/macro';
export const StyledInput = styled.input`
  height: 48px;
  width: 48px;

  color: ${({ theme }) => theme.color.gray2};
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 5px;
  outline: none;
  border: 2px solid #e0e0e0;

  @media (max-width: 575.98px) {
    height: 42px;
    width: 42px;
    font-size: 20px;
  }
`;
