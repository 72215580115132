import styled from 'styled-components/macro';
export const StyledContainer = styled.div`
  width: 100%;
  max-width: 600px;
  font-size: 16px;
  color: ${({ theme }) => theme.color.gray2};
  margin: 0 auto;
`;

export const StyledSvgContainer = styled.div`
  text-align: center;
`;

export const StyledInnerContainer = styled.div`
  max-width: 400px;
  margin: 32px auto 0 auto;

  @media (max-width: 575.98px) {
    padding: 0 32px;
    font-size: 12px;
  }
`;

export const StyledList = styled.ul`
  padding-left: 25px;
  margin: 30px 0;
`;
