import { format, parse } from 'date-fns';
import { ISubscriptionData } from 'model/contract-details';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import contractService from 'services/contract-service';
import i18n from '../config/i18n';
import companyService from 'services/company-service';
import SignatureMethods from '../model/enums/signature-methods';
import { RootState } from '../redux/storeConfig';
import LocalStorageKeys from 'model/enums/local-storage-keys';

const helperPath = (signatureMethod?: SignatureMethods) => {
  const path = {
    [SignatureMethods.SELFIE]: '/selfie',
    [SignatureMethods.TOKEN]: '/token',
    [SignatureMethods.SIGNATURE]: '/assinatura',
    DEFAULT: '*',
  };

  return path[signatureMethod ?? 'DEFAULT'];
};

export const routeNextStepSignature = (signatureMethod: SignatureMethods, allSignatureMethodsInContract?: SignatureMethods[]) => {
  const indexCurrentSignatureMethod = allSignatureMethodsInContract?.indexOf(signatureMethod);

  if (indexCurrentSignatureMethod != null) {
    const nextSignatureMethod = allSignatureMethodsInContract?.[indexCurrentSignatureMethod + 1];

    if (nextSignatureMethod != null) {
      return helperPath(nextSignatureMethod);
    }
  }
};

enum TokenErrorType {
  TOKEN_EXPIRED = 'error.token.expired',
}

const useHelperNavigation = () => {
  const { contract } = useSelector((state: RootState) => state);
  const navigate = useNavigate();

  const [errorMessageSignContract, setErrorMessageSignContract] = useState<string>('');
  const [isLoadingSendingContractSignature, setIsLoadingSendingContractSignature] = useState<boolean>(false);

  const handleSendBatchSignature = (data: ISubscriptionData) => {
    companyService
      .signBatchContract(data)
      .then(() => {
        navigate('/assinatura-finalizada');
      })
      .catch(error => {
        if (error.data.code === TokenErrorType.TOKEN_EXPIRED) {
          setErrorMessageSignContract(i18n.t('global.errorMessage.expiredToken'));
        } else {
          setErrorMessageSignContract(i18n.t('global.errorMessage.invalidToken'));
        }
      })
      .finally(() => setIsLoadingSendingContractSignature(false));
  };

  const handleSendSignature = (data: ISubscriptionData, subscriptionKey: string) => {
    contractService
      .signContract(data, subscriptionKey)
      .then(() => {
        navigate('/assinatura-finalizada');
      })
      .catch(error => {
        if (error.data.code === TokenErrorType.TOKEN_EXPIRED) {
          setErrorMessageSignContract(i18n.t('global.errorMessage.expiredToken'));
        } else {
          setErrorMessageSignContract(i18n.t('global.errorMessage.invalidToken'));
        }
      })
      .finally(() => setIsLoadingSendingContractSignature(false));
  };

  const handleSendEnvelopeSignature = (data: ISubscriptionData, subscriptionKey: string) => {
    contractService
      .signEnvelope(data, subscriptionKey)
      .then(() => {
        navigate('/assinatura-finalizada');
      })
      .catch(error => {
        if (error.data.code === TokenErrorType.TOKEN_EXPIRED) {
          setErrorMessageSignContract(i18n.t('global.errorMessage.expiredToken'));
        } else {
          setErrorMessageSignContract(i18n.t('global.errorMessage.invalidToken'));
        }
      })
      .finally(() => setIsLoadingSendingContractSignature(false));
  };

  const sendSignature = (subscriptionData?: ISubscriptionData, isEnvelopeSignature?: boolean) => {
    if (subscriptionData?.personSignature?.birth != null) {
      const isBatchSubscription = contract.contracDetails?.batchSignatureData != null;

      const formattedDate = format(parse(subscriptionData.personSignature.birth, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
      const formattedSubscriptionData: ISubscriptionData = {
        ...subscriptionData,
        personSignature: {
          ...subscriptionData.personSignature,
          birth: formattedDate,
        },
      };

      setIsLoadingSendingContractSignature(true);

      if (isBatchSubscription) {
        handleSendBatchSignature(formattedSubscriptionData);
        return;
      }

      if (isEnvelopeSignature && contract.contracDetails?.subscriptionKey != null) {
        handleSendEnvelopeSignature(formattedSubscriptionData, contract.contracDetails.subscriptionKey);
        return;
      }

      if (contract.contracDetails?.subscriptionKey != null) {
        handleSendSignature(formattedSubscriptionData, contract.contracDetails.subscriptionKey);
      }

      localStorage.removeItem(LocalStorageKeys.TOKEN);
    }
  };

  return {
    errorMessageSignContract,
    isLoadingSendingContractSignature,
    sendSignature,
  };
};

export default useHelperNavigation;
