import styled from 'styled-components/macro';
export const StyledContainer = styled.div`
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  line-height: 40px;

  @media (max-width: 575.98px) {
    padding: 0 32px;
  }

  @media (max-width: 767.98px) {
    max-width: 400px;
    text-align: center;
  }
`;

export const StyledInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
  row-gap: 40px;
  column-gap: 8%;
`;

export const StyledCardShowContractContainer = styled.div`
  max-width: 256px;
  margin: auto 0;

  @media (max-width: 767.98px) {
    width: 100%;
    max-width: 400px;
  }
`;

export const StyledSubtitle = styled.div`
  color: ${({ theme }) => theme.color.gray2};
  font-family: ${({ theme }) => theme.font.semiBold};
  font-size: 16px;
  text-align: left;

  @media (max-width: 575.98px) {
    font-size: 12px;
  }
`;

export const StyledInstructionsContainer = styled.div`
  width: 100%;
  max-width: 400px;
`;

export const StyledInstructions = styled.div`
  display: flex;
`;

export const StyledList = styled.ul`
  font-size: 14px;
  color: #656666;

  padding-left: 40px;
  text-align: left;
  line-height: 20px;

  li {
    margin-bottom: 5px;
  }
`;

export const StyledPhotoPreview = styled.img`
  width: 100%;
  border-radius: 8px;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  column-gap: 8px;
  width: 100%;
`;
