import styled from 'styled-components/macro';
export const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 992px;
  margin: 0 auto;
`;

export const StyledTableCotainer = styled.div`
  margin: 24px 0;
`;

export const StyledFiltersContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: baseline;
  column-gap: 10px;
  margin-bottom: 15px;
`;

export const StyledClearButtonIcon = styled.div`
  display: flex;

  svg {
    path {
      fill: ${({ theme }) => theme.color.colorPrimary};
    }
  }
`;

export const StyledTableHeaderInnerContainer = styled.div`
  height: 25px;
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

export const StyledTableHeader = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray24};
`;

export const StyledTag = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.color.gray21};
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
`;
