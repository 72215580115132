import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Snackbar } from '@mui/material';
import { useLazyGetSessionQuery, useLoginMutation } from 'api/endpoints/authentication';
import GenericSignIn from 'components/generic-signIn';
import useRequestErrorHandler from 'hooks/useRequestErrorHandler';
import _ from 'lodash';
import { Authentication } from 'model/authentication';
import { InputNames } from 'model/enums/input-names';
import { InputTypes } from 'model/enums/input-types';
import { RoutePath } from 'model/enums/routes-path';
import { GenericFormData } from 'model/generic-form-data';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StringUtils, { validateCpf } from 'shared/util/string-utils';
import * as yup from 'yup';

interface SignInProps {}
const SignIn: React.FC<SignInProps> = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<GenericFormData>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subtitles = [t('signFlow.signin.subtitle')];
  const [login, { isLoading }] = useLoginMutation();
  const [getSession, { isLoading: isSessionLoading }] = useLazyGetSessionQuery();

  const { openAlert, errorMessage, makeRequestWithErrorHandler } = useRequestErrorHandler();

  const inputList = [
    {
      label: t('confirmDetails.individualRegistration'),
      name: InputNames.CPF,
      type: InputTypes.TEXT,
      placeholder: t('global.field.placeholders.cpf'),
      formData: formData,
      setFormData: setFormData,
      mask: StringUtils.cpfMask,
      maxLength: 14,
    },
    {
      label: t('signFlow.password'),
      name: InputNames.PASSWORD,
      type: InputTypes.PASSWORD,
      placeholder: t('signFlow.enterThePassword'),
      formData: formData,
      setFormData: setFormData,
    },
  ];
  const schema = yup.object().shape({
    [InputNames.CPF]: yup
      .string()
      .test(`test-cpf`, t('global.errorMessage.invalidCpf'), numbers => validateCpf(numbers!))
      .required(t('global.field.validations.required')),
    [InputNames.PASSWORD]: yup.string().required(t('global.field.validations.required')),
  });

  const handleForm = async () => {
    const isValid = await methods.trigger();
    if (!isValid) {
      return;
    }

    const loginData: Authentication = {
      username: formData?.cpf,
      password: formData?.password,
    };

    makeRequestWithErrorHandler(async () => {
      await login(loginData).unwrap();
      await getSession().unwrap();
      navigate(RoutePath.DASHBOARD);
    });
  };
  const methods = useForm({ resolver: yupResolver(schema), mode: 'onSubmit' });

  const handleSubmit = _.debounce(handleForm, 500);

  return (
    <>
      <Snackbar open={openAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <GenericSignIn
        title={t('global.button.enter')}
        subtitles={subtitles}
        inputList={inputList}
        buttonText={t('global.button.enter')}
        handleSubmit={handleSubmit}
        methods={methods}
        formData={formData}
        setFormData={setFormData}
        hasBackButton={true}
        missingPassword={true}
        route={RoutePath.FORGOT_PASSWORD}
        isLoading={isLoading}
      />
    </>
  );
};

export default SignIn;
