import { AxiosResponse } from 'axios';
import { api } from './api';
import ISignedDocument from 'model/signed-document';

const signGroupsApi = () => {
  const getSignedContracts = (uuid: string): Promise<AxiosResponse<ISignedDocument>> => {
    return api.get<ISignedDocument>(`/sign-groups/uuid/${uuid}`);
  };

  return {
    getSignedContracts,
  };
};

export default signGroupsApi();
