import styled from 'styled-components/macro';
interface IStyledProps {
  isDisabled: boolean;
}

export const StyledContainer = styled.div`
  height: 100%;
  overflow: hidden;

  background-color: ${({ theme }) => theme.color.gray3};
  padding-bottom: 160px;
`;

export const StyledHeaderContainer = styled.div`
  height: 60px;
  background-color: ${({ theme }) => theme.color.colorPrimary};
  margin-bottom: 24px;
`;

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 960px;
  height: 100%;

  margin: auto;

  @media (max-width: 1000.98px) {
    padding: 0 4vh;
  }

  path {
    fill: ${({ theme }) => theme.color.colorPrimary};
  }
`;

export const StyledContractContainer = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;

  .react-pdf__Document {
    height: 100%;
    width: 100%;
    max-width: 960px;
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
    max-width: 960px;

    margin-bottom: 15px;
  }

  @media (max-width: 1000.98px) {
    padding: 0 1.8vh;
  }
`;

export const StyledFooter = styled.footer`
  height: 96px;
  width: 100%;

  position: fixed;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5%;

  background-color: ${({ theme }) => theme.color.backgroundGray};
`;

export const StyledLink = styled.a<IStyledProps>`
  pointer-events: ${({ isDisabled }) => isDisabled && 'none'};
  margin-right: 14px;

  @media (max-width: 575.98px) {
    margin: 0px;

    svg {
      height: 30px;
    }
  }
`;

export const StyledButton = styled.button`
  border: none;
  background: none;
  padding: 0;

  svg {
    @media (max-width: 575.98px) {
      height: 30px;
    }
  }
`;

export const StyledLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
