import networkDataApi from 'api/network-data-api';
import { AxiosError, AxiosResponse } from 'axios';
import INetworkData from 'model/network-data';

export const networkDataService = () => {
  const getInternetProtocol = async () => {
    try {
      const result: AxiosResponse<string> = await networkDataApi.getInternalApiInternetProtocol();
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      try {
        const publicApiResult: AxiosResponse<INetworkData> = await networkDataApi.getPublicApiInternetProtocol();
        if (publicApiResult.status === 200 && publicApiResult.data.IPv4 != null) {
          return Promise.resolve(publicApiResult.data.IPv4);
        }

        return Promise.reject({ status: publicApiResult.status } as AxiosResponse);
      } catch {
        const { response } = error as AxiosError;

        console.error(response);
        return Promise.reject(response);
      }
    }
  };

  return {
    getInternetProtocol,
  };
};

export default networkDataService();
