import ButtonArrow from 'components/button-arrow/ButtonArrow';
import GenericTable from 'components/generic-table/GenericTable';
import PageTitleSection from 'components/page-title-section/PageTitleSection';
import Status from 'components/status/Status';
import UseCardVerificationContext from 'context/card-verification';
import IDocument, { ITypeContractsFilter } from 'model/document';
import LocalStorageKeys from 'model/enums/local-storage-keys';
import { RoutePath } from 'model/enums/routes-path';
import { PageableResponse, PaginationParameters } from 'model/pageable';
import ITypeGenericTable from 'model/type-generic-table';
import { useContext, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import companyService from 'services/company-service';
import { convertDateTimeFromServer, formatDateLocal } from 'shared/util/date-utils';
import { StyledContainer, StyledFiltersContainer, StyledTableCotainer } from './styles';
import CalendarFilter from 'components/calendar-filter/CalendarFilter';
import { StyledTextField } from 'styled-components/StyledTextField';
import { isEmpty } from 'lodash';

const SubscriptionHistory = ({ t }: WithTranslation) => {
  const companyName = localStorage.getItem(LocalStorageKeys.SIGNATURE_COMPANY_NAME);
  const uuid = localStorage.getItem(LocalStorageKeys.SIGNATURE_UUID);
  const navigate = useNavigate();

  const [documents, setDocuments] = useState<PageableResponse<IDocument>>();
  const [page, setPage] = useState<number>(0);

  const [filterTable, setFilterTable] = useState<ITypeContractsFilter>();
  const [timeoutCcbSearch, setTimeoutCcbSearch] = useState<NodeJS.Timeout | null>(null);

  const { hasOnlyOneCompany, companyLoading } = useContext(UseCardVerificationContext);

  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsTableLoading(true);

    const pagination: PaginationParameters = {
      page,
      size: 7,
      ...filterTable,
    };

    if (uuid != null) {
      companyService.getDocumentSignatureHistory(uuid, pagination).then(response => {
        setDocuments(response);
        setIsTableLoading(false);
      });
    }
  }, [page, filterTable]);

  useEffect(() => {
    return () => {
      if (timeoutCcbSearch != null) {
        clearTimeout(timeoutCcbSearch);
      }
    };
  }, []);

  const handleCcbInput = (value: string) => {
    if (timeoutCcbSearch != null) {
      clearTimeout(timeoutCcbSearch);
    }

    setTimeoutCcbSearch(
      setTimeout(() => {
        setFilterTable({ ...filterTable, name: value ?? '' });
      }, 500)
    );
  };

  const handleChangeDateFilter = (date: [Date | null, Date | null]) => {
    setFilterTable({ ...filterTable, initialDate: convertDateTimeFromServer(date[0]), finalDate: convertDateTimeFromServer(date[1]) });
  };

  const dataGenericTable: ITypeGenericTable = {
    headerData: [
      t('subscriptionsHistory.table.contractName'),
      t('subscriptionsHistory.table.signatureDate'),
      t('subscriptionsHistory.table.status'),
      '',
    ],
    tableData: documents?.content.map(document => ({
      name: document.name,
      data: formatDateLocal(document.originalDocumentDate),
      status: <Status status={document.status} />,
      seeContract: (
        <ButtonArrow
          text={t('global.button.seeContract')}
          onClick={() => navigate('/contrato', { state: document })}
          style={{ margin: '0px' }}
        />
      ),
    })),
  };

  return (
    <StyledContainer>
      <PageTitleSection
        i18nKeyTitle={companyName ?? ''}
        showBackButton={!hasOnlyOneCompany}
        redirect={() => navigate(RoutePath.DASHBOARD)}
        loadingBackButton={companyLoading}
      />

      <StyledTableCotainer>
        <StyledFiltersContainer>
          <CalendarFilter label={t('pendingSubscriptions.table.selectDate')} onChange={date => handleChangeDateFilter(date)} />
          <StyledTextField
            label={t('pendingSubscriptions.table.contractName')}
            onChange={({ target }) => handleCcbInput(target.value)}
            inputProps={{ maxLength: 255 }}
          />
        </StyledFiltersContainer>
        <GenericTable
          isTableLoading={isTableLoading}
          setIsTableLoading={setIsTableLoading}
          headerData={dataGenericTable.headerData}
          tableData={dataGenericTable.tableData}
          totalPages={documents?.totalPages}
          currentPage={documents?.pageable.pageNumber}
          paginationValue={setPage}
        />
      </StyledTableCotainer>
    </StyledContainer>
  );
};

export default withTranslation()(SubscriptionHistory);
