import styled from 'styled-components/macro';
export const StyledContainer = styled.div`
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  line-height: 40px;

  @media (max-width: 575.98px) {
    text-align: center;
    padding: 0 32px;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 400px;
  }
`;

export const StyledInnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 40px;
  margin-top: 64px;

  @media (max-width: 575.98px) {
    margin-top: 24px;
  }
`;

export const StyledCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 24px;
  max-width: 400px;

  @media (max-width: 767.98px) {
    margin: 0 auto;
  }
`;

export const StyledCardShowContractContainer = styled.div`
  max-width: 256px;

  @media (max-width: 767.98px) {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
`;
