import Modal from 'react-modal';
import { ReactComponent as ClearIconSvg } from '../../assets/images/audit-sign/clear.svg';
import { StyledButtonContainer, StyledContainer, StyledIconContainer, StyledTitle } from './styles';
import { ReactNode } from 'react';
import BlueGenericButton from 'components/blue-generic-button/BlueGenericButton';
import { WithTranslation, withTranslation } from 'react-i18next';
import useWindowSize from 'hooks/useWindowSize';

interface IProps extends WithTranslation {
  isOpen: boolean;
  icon?: ReactNode;
  title?: string;
  text?: string;
  onCloseModal: () => void;
}

const GenericModal = ({ isOpen, icon, title, text, onCloseModal, t }: IProps) => {
  const [width] = useWindowSize();

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 23px',
    },
    content: {
      width: width > 991.98 ? '100%' : '90%',
      maxWidth: '560px',
      height: '100%',
      maxHeight: '347px',
      inset: 'initial',
      padding: 0,
      border: 'none',
      borderRadius: '8px',
      boxShadow: '0px 6px 10px 4px rgba(0, 0, 0, 0.05), 0px 2px 3px rgba(0, 0, 0, 0.1)',
    },
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={() => onCloseModal()} style={customStyles} ariaHideApp={false}>
      <StyledIconContainer onClick={() => onCloseModal()}>
        <ClearIconSvg />
      </StyledIconContainer>

      <StyledContainer>
        {icon != null && icon}
        <div>
          {title != null && <StyledTitle>{title}</StyledTitle>}
          {text != null && text}
        </div>
      </StyledContainer>

      <StyledButtonContainer>
        <BlueGenericButton onClick={() => onCloseModal()}>{t('global.button.ok')}</BlueGenericButton>
      </StyledButtonContainer>
    </Modal>
  );
};

export default withTranslation()(GenericModal);
