import styled from 'styled-components/macro';
export const StyledContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  line-height: 40px;
  text-align: left;

  color: ${({ theme }) => theme.color.gray2};
  font-size: 16px;

  .highlighted {
    font-family: ${({ theme }) => theme.font.semiBold};
  }

  @media (max-width: 575.98px) {
    padding: 0 32px;
    font-size: 12px;
  }
`;

export const StyledInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 16px;
  column-gap: 8%;
`;

export const StyledCardShowContractContainer = styled.div`
  width: 100%;
  max-width: 400px;
`;

export const StyledTokenContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;

  margin: 40px 0;

  @media (max-width: 575.98px) {
    justify-content: start;
  }
`;

export const StyledButton = styled.a`
  cursor: pointer;
`;

export const StylesTerms = styled.div`
  font-size: 12px;
  line-height: 1.4;
  margin-top: 32px;

  .bold-text {
    font-family: ${({ theme }) => theme.font.semiBold};
    color: ${({ theme }) => theme.color.gray2};
    text-decoration: none;
  }
`;
