import { ReactComponent as CheckboxNotSelectedSvg } from '../../assets/images/audit-sign/checkbox-not-selected.svg';
import { ReactComponent as CheckboxSelectedSvg } from '../../assets/images/audit-sign/checkbox-selected.svg';
import { StyledContainer } from './styled';

interface IProps {
  selected: boolean;
  text?: string;
  handleClick?: () => void;
}

const CustomCheckbox = ({ selected, text, handleClick }: IProps) => {
  return (
    <StyledContainer
      onClick={() => {
        if (handleClick != null) {
          handleClick();
        }
      }}
    >
      {selected ? <CheckboxSelectedSvg /> : <CheckboxNotSelectedSvg />}
      {text != null && <div>{text}</div>}
    </StyledContainer>
  );
};

export default CustomCheckbox;
