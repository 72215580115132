import styled from 'styled-components/macro';
export const StyledContainer = styled.div`
  width: 100%;
  max-width: 600px;
  font-size: 16px;
  color: ${({ theme }) => theme.color.gray2};
  margin: 0 auto;
`;

export const StyledSvgContainer = styled.div`
  text-align: center;

  svg > path:nth-child(39) {
    stroke: ${({ theme }) => theme.color.colorPrimary};
  }

  svg > path:nth-child(43) {
    fill: ${({ theme }) => theme.color.colorPrimary};
  }

  svg > path:nth-child(62) {
    fill: ${({ theme }) => theme.color.colorPrimary};
  }
`;

export const StyledButtonContainer = styled.div`
  width: 150px;
  margin: 0 auto;
`;
