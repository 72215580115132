import styled from 'styled-components';

export const SmallLoadingWrapper = styled.div`
  width: 50px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  .small-loading-wrap {
    width: 100%;
    height: 100%;

    border-radius: 50%;
    border: 3px solid #d0e2ff;
    border-top: 3px solid #0f62fe;

    animation: spin-animation 2s linear infinite;
    -webkit-animation: spin-animation 2s linear infinite;
  }

  @-webkit-keyframes spin-animation {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
