import { useFormContext } from 'react-hook-form';
import { StyledInput } from './styles';

interface IProps {
  name: string;
  maxLength?: number;
}

const InputToken = ({ name, maxLength }: IProps) => {
  const { register } = useFormContext();

  return <StyledInput {...register(name)} id={name} maxLength={maxLength ?? 1} />;
};

export default InputToken;
