import * as React from 'react';
import { CommonLoading } from 'react-loadingg';
import { StyledContainer } from './styled';

interface ILoadingContainerScreenProps {
  isLoading?: boolean;
  children: JSX.Element | JSX.Element[] | any;
}

export class LoadingContainerScreen extends React.Component<ILoadingContainerScreenProps> {
  constructor(props) {
    super(props);
  }
  render() {
    const { isLoading, children } = this.props;
    return isLoading ? (
      <StyledContainer>
        <CommonLoading />
      </StyledContainer>
    ) : (
      children
    );
  }
}

export default LoadingContainerScreen;
