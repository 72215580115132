import { createAsyncThunk } from '@reduxjs/toolkit';
import { removeAccount } from 'redux/slice/authentication';
import AuthUtils from 'shared/util/auth-utils';

export const logout = createAsyncThunk('authentication/logout', async (_, thunkApi) => {
  await AuthUtils.removeToken();
  await AuthUtils.removeAccount();
  thunkApi.dispatch(removeAccount());

  return;
});
