import PageTitleSection from 'components/page-title-section/PageTitleSection';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ReactComponent as ErrorRegistrationSvg } from '../../assets/images/audit-sign/error-registration.svg';
import { StyledContainer, StyledInnerContainer, StyledList, StyledSvgContainer } from './styles';

const InvalidSubscriptionKey = ({ t }: WithTranslation) => {
  return (
    <StyledContainer>
      <PageTitleSection i18nKeyTitle="invalidSubscriptionKey.title" style={{ justifyContent: 'center' }} />

      <StyledSvgContainer>
        <ErrorRegistrationSvg />
      </StyledSvgContainer>

      <StyledInnerContainer>
        <div>{t('invalidSubscriptionKey.whatHappened')}</div>
        <StyledList>
          <li>{t('invalidSubscriptionKey.documentSigned')}</li>
          <li>{t('invalidSubscriptionKey.codeLongerAvailable')}</li>
        </StyledList>
        <div>{t('invalidSubscriptionKey.checkCompanyOrRequestShipment')}</div>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default withTranslation()(InvalidSubscriptionKey);
