import styled from 'styled-components/macro';
export const StyledContainer = styled.div`
  max-width: 73px;
  display: flex;
  align-items: center;
  column-gap: 2px;

  line-height: 1;
  font-weight: 700;
  font-size: 10px;
  color: ${({ theme }) => theme.color.gray50};
  cursor: pointer;
`;
