import signGroupsApi from 'api/sign-groups-api';
import { AxiosError, AxiosResponse } from 'axios';
import ISignedDocument from 'model/signed-document';

export const signGroupsService = () => {
  const getSignedContracts = async (uuid: string) => {
    try {
      const result: AxiosResponse<ISignedDocument> = await signGroupsApi.getSignedContracts(uuid);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getSignedContracts,
  };
};

export default signGroupsService();
