import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useAppDispatch } from 'redux/hooks';
import { logout } from 'redux/thunk/authentication';

const Logout: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const requestLogout = async () => {
      await dispatch(logout());
      window.location.replace('/');
    };
    requestLogout();
  }, [dispatch]);

  return (
    <Row className="base-full-height">
      <Col md={12} className={'base-centered-column'}>
        <LoadingContainerScreen isLoading children={void 0} />
      </Col>
    </Row>
  );
};

export default Logout;
