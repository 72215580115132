import CardShowContract from 'components/card-show-contract/CardShowContract';
import PageTitleSection from 'components/page-title-section/PageTitleSection';
import { StyledCardShowContractContainer, StyledContainer, StyledInnerContainer, StyledQRCodeContainer } from './styles';
import 'react-html5-camera-photo/build/css/index.css';
import { QRCodeSVG } from 'qrcode.react';

const GoToMobile = () => {
  const location = window.location.href.replace('qr-code', 'assinar');

  return (
    <StyledContainer>
      <PageTitleSection i18nKeyTitle="goToMobile.title" i18nKeySubtitle="goToMobile.subtitle" textAlign="left" />

      <StyledInnerContainer>
        <StyledQRCodeContainer>
          <QRCodeSVG value={location} />
        </StyledQRCodeContainer>

        <StyledCardShowContractContainer>
          <CardShowContract />
        </StyledCardShowContractContainer>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default GoToMobile;
