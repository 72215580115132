import BlueGenericButton from 'components/blue-generic-button/BlueGenericButton';
import CardShowContract from 'components/card-show-contract/CardShowContract';
import PageTitleSection from 'components/page-title-section/PageTitleSection';
import useHelperNavigation, { routeNextStepSignature } from 'hooks/useHelperNavigation';
import { ISubscriptionData } from 'model/contract-details';
import SignatureMethods from 'model/enums/signature-methods';
import { useEffect, useRef, useState } from 'react';
import 'react-html5-camera-photo/build/css/index.css';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { RootState } from 'redux/storeConfig';
import { addSignatureData } from '../../redux/slice/data-signing-contract';
import { StyledButtonsContainer, StyledCardShowContractContainer, StyledContainer, StyledInnerContainer } from './styles';

const Signature = ({ t }: WithTranslation) => {
  const documentRef = useRef<SignatureCanvas | null>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { contract, signature } = useSelector((state: RootState) => state);
  const { isLoadingSendingContractSignature, sendSignature } = useHelperNavigation();

  const [isSignatureEmpty, setIsSignatureEmpty] = useState<boolean>(true);

  useEffect(() => {
    if (signature.subscriptionData?.personSignature?.signature?.file != null) {
      documentRef.current?.fromDataURL(`data:image/jpg;base64,${signature.subscriptionData.personSignature.signature.file}`);
      setIsSignatureEmpty(false);
    }
  }, []);

  const handleSendSignature = () => {
    const nextStep = routeNextStepSignature(SignatureMethods.SIGNATURE, contract.contracDetails?.signatureMethods);
    const base64Signature = documentRef.current?.toDataURL().split(',')[1];

    if (base64Signature != null) {
      const subscriptionData: ISubscriptionData = {
        personSignature: {
          lat: signature.subscriptionData?.personSignature?.lat,
          lng: signature.subscriptionData?.personSignature?.lng,
          ip: signature.subscriptionData?.personSignature?.ip,
          name: signature.subscriptionData?.personSignature?.name,
          birth: signature.subscriptionData?.personSignature?.birth,
          selfie: signature.subscriptionData?.personSignature?.selfie,
          signature: { file: base64Signature },
        },
      };

      dispatch(addSignatureData(subscriptionData));

      if (nextStep != null) {
        navigate(nextStep);
      } else {
        const isEnvelopeSignature = contract.contracDetails?.envelope != null;
        const updatedSubscriptionData: ISubscriptionData = {
          ...signature.subscriptionData,
          personSignature: subscriptionData.personSignature,
        };

        sendSignature(updatedSubscriptionData, isEnvelopeSignature);
      }
    }
  };

  const handleCleanSignature = () => {
    setIsSignatureEmpty(true);
    documentRef.current?.clear();
  };

  return (
    <StyledContainer>
      <PageTitleSection
        signatureMethods={contract.contracDetails?.signatureMethods}
        currentSignatureMethod={SignatureMethods.SIGNATURE}
        i18nKeyTitle="signature.title"
        i18nKeySubtitle="signature.subtitle"
        textAlign="left"
      />
      <StyledInnerContainer>
        <SignatureCanvas canvasProps={{ className: 'sigCanvas' }} onEnd={() => setIsSignatureEmpty(false)} ref={documentRef} />

        <StyledButtonsContainer>
          <BlueGenericButton invertButtonColor onClick={() => handleCleanSignature()}>
            {t('global.button.clean')}
          </BlueGenericButton>
          <BlueGenericButton
            isLoading={isLoadingSendingContractSignature}
            disabled={isSignatureEmpty}
            onClick={() => handleSendSignature()}
          >
            {t('global.button.send')}
          </BlueGenericButton>
        </StyledButtonsContainer>

        <StyledCardShowContractContainer>
          <CardShowContract />
        </StyledCardShowContractContainer>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default withTranslation()(Signature);
