import React, { useEffect, useState } from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { isAfter } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';
import { StyledContainer, StyledInput } from './styles';
import { APP_LOCAL_DATE_FORMAT } from 'config/constants';
import { isEmpty } from 'lodash';

interface IProps {
  onChange: (selectedDates: [Date | null, Date | null], event: React.SyntheticEvent<any>) => void;
  label: string;
}

const locale = 'pt-BR';

const CalendarInput = ({ label, onChange }: IProps) => {
  const [selectedDates, setSelectedDates] = useState<[Date | null, Date | null]>([null, null]);

  const handleDateChange = (dates: [Date | null, Date | null], event: React.SyntheticEvent<any>) => {
    const [start, end] = dates;

    if (start && end && !isAfter(end, start)) {
      return;
    }

    setSelectedDates(dates);
    onChange(dates, event);
  };

  registerLocale(locale, ptBR);
  setDefaultLocale(locale);

  const isFilled = !!(selectedDates[0] || selectedDates[1]);

  return (
    <StyledContainer isFilled={isFilled}>
      <StyledInput
        selected={selectedDates[0]}
        onChange={handleDateChange}
        onBlur={event => {
          if (isEmpty(event.target.value)) {
            handleDateChange([null, null], event);
            event.target.value = '';
          }
        }}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        selectsRange
        dateFormat={APP_LOCAL_DATE_FORMAT}
        locale={locale}
        autoComplete="off"
      />
      <label>{label}</label>
    </StyledContainer>
  );
};

export default CalendarInput;
