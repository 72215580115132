import styled from 'styled-components/macro';
export const StyledContainer = styled.div`
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  line-height: 40px;

  @media (max-width: 575.98px) {
    padding: 0 32px;
  }

  @media (max-width: 767.98px) {
    max-width: 400px;
    text-align: center;
  }
`;

export const StyledInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
  row-gap: 40px;
  column-gap: 8%;
`;

export const StyledCardShowContractContainer = styled.div`
  max-width: 256px;
  margin: auto 0;

  @media (max-width: 767.98px) {
    width: 100%;
    max-width: 400px;
  }
`;

export const StyledQRCodeContainer = styled.div`
  width: 100%;
  max-width: 400px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
