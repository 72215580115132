import LocalStorageKeys from 'model/enums/local-storage-keys';

const useUrlToken = () => {
  const getTokenFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('token');
  };

  const getTokenFromStorage = () => {
    return localStorage.getItem(LocalStorageKeys.TOKEN);
  };

  const setTokenToStorage = (token?: string | null) => {
    localStorage.removeItem(LocalStorageKeys.TOKEN);
    if (token) {
      localStorage.setItem(LocalStorageKeys.TOKEN, token);
    }
  };

  const hasUrlToken = () => {
    return getTokenFromStorage() != null;
  };

  return {
    getTokenFromUrl,
    setTokenToStorage,
    getTokenFromStorage,
    hasUrlToken,
  };
};

export default useUrlToken;
