import styled from 'styled-components/macro';
export const StyledContainer = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  max-width: 400px;
  height: 80px;
  padding: 0 32px;

  color: ${({ theme }) => theme.color.gray2};
  font-size: 16px;
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.05), 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;

  @media (max-width: 575.98px) {
    font-size: 12px;
    padding: 0 16px;
  }
`;

export const StyledInnerContainer = styled.div`
  display: flex;
  text-align: left;

  circle {
    fill: ${({ theme }) => theme.color.colorPrimary};
  }

  rect {
    fill: ${({ theme }) => theme.color.colorPrimary};
  }
`;

export const StyledTextContainer = styled.div`
  line-height: 1.3;
  margin-left: 12px;

  @media (max-width: 575.98px) {
    margin-left: 8px;
  }
`;

export const StyledTitle = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.font.semiBold}, sans-serif;
`;
