import { ReactComponent as SubscriptionCompletedSvg } from '../../assets/images/audit-sign/subscription-completed.svg';
import { StyledContainer, StyledTitle, StyledSvgContainer, StyledLinkContainer } from './styles';
import { useParams } from 'react-router-dom';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import signGroupsService from 'services/sign-groups-service';
import { useEffect } from 'react';

const DownloadSignedDocuments = ({ t }: WithTranslation) => {
  const { uuid } = useParams();

  useEffect(() => {
    downloadZipSignedContracts();
  }, []);

  const downloadZipSignedContracts = () => {
    if (uuid != null) {
      signGroupsService.getSignedContracts(uuid).then(response => {
        if (response.attachment?.presignedUrl != null) {
          const linkSource = response.attachment.presignedUrl;
          const link = document.createElement('a');
          link.href = linkSource;
          link.click();
        }
      });
    }
  };

  return (
    <StyledContainer>
      <StyledTitle>{t('screenDownloadDocuments.text')}</StyledTitle>

      <StyledSvgContainer>
        <SubscriptionCompletedSvg />
      </StyledSvgContainer>

      <StyledLinkContainer>
        <Trans i18nKey="screenDownloadDocuments.caseNeedDownloadAgain">
          <span onClick={() => downloadZipSignedContracts()} className="bold-text">
            {{ clickHere: t('screenDownloadDocuments.clickHere') }}
          </span>
        </Trans>
      </StyledLinkContainer>
    </StyledContainer>
  );
};

export default withTranslation()(DownloadSignedDocuments);
