import * as S from './styles';
import PageTitleSection from 'components/page-title-section/PageTitleSection';
import BlueGenericButton from 'components/blue-generic-button/BlueGenericButton';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/storeConfig';
import { useEffect, useState } from 'react';
import contractService from 'services/contract-service';
import { useNavigate } from 'react-router-dom';
import SignatureMethods from 'model/enums/signature-methods';
import ContractDataCard from 'components/contract-data-card';
import useUrlToken from 'hooks/useUrlToken';

const ViewMultipleContractsToSign = ({ t }: WithTranslation) => {
  const navigate = useNavigate();
  const { contract } = useSelector((state: RootState) => state);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isContractNotSeen, setIsContractNotSeen] = useState<boolean>(true);

  const { hasUrlToken } = useUrlToken();

  useEffect(() => {
    if (contract.contracDetails?.envelope?.documents.map(value => value.seen).includes(false)) {
      setIsContractNotSeen(true);
    } else {
      setIsContractNotSeen(false);
    }
  }, [contract]);

  const handleToSign = async () => {
    setIsLoading(true);

    if (contract.contracDetails?.subscriptionKey != null && contract.contracDetails?.signatureMethods?.includes(SignatureMethods.TOKEN)) {
      if (!hasUrlToken()) {
        await contractService.sendTokenToEmail(contract.contracDetails?.subscriptionKey);
      }
    }

    setIsLoading(false);
    navigate('/etapas-assinatura');
  };

  return (
    <S.Container>
      <PageTitleSection
        i18nKeyTitle="viewMultipleContractsToSign.title"
        i18nKeySubtitle="viewMultipleContractsToSign.subtitle"
        interpolatedValue={contract.contracDetails?.envelope?.documents.length}
        textAlign="left"
      />

      <S.CardContainer>
        {contract.contracDetails?.envelope?.documents.map((value, index) => (
          <ContractDataCard documentValue={value} key={`contract-${index}`} showPreviewStatusIcon />
        ))}
      </S.CardContainer>

      <S.Footer>
        <BlueGenericButton disabled={isContractNotSeen} isLoading={isLoading} onClick={() => handleToSign()}>
          {t('global.button.toSign')}
        </BlueGenericButton>
        <S.Text>{t('viewMultipleContractsToSign.youMustPreviewDocument')}</S.Text>
      </S.Footer>
    </S.Container>
  );
};

export default withTranslation()(ViewMultipleContractsToSign);
