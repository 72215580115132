import styled from 'styled-components/macro';
export const StyledContainer = styled.div`
  width: 100%;
  max-width: 600px;
  font-size: 16px;
  color: ${({ theme }) => theme.color.gray2};
  margin: 0 auto;
`;

export const StyledSvgContainer = styled.div`
  text-align: center;

  svg > path:nth-child(39) {
    stroke: ${({ theme }) => theme.color.colorPrimary};
  }

  svg > path:nth-child(43) {
    fill: ${({ theme }) => theme.color.colorPrimary};
  }

  svg > path:nth-child(62) {
    fill: ${({ theme }) => theme.color.colorPrimary};
  }
`;

export const StyledTitle = styled.div`
  max-width: 531px;
  margin: 0 auto;

  color: ${({ theme }) => theme.color.gray2};
  font-size: 16px;
  line-height: 1.4;

  @media (max-width: 575.98px) {
    font-size: 12px;
    width: 261px;
    line-height: 1.3;
  }
`;

export const StyledLinkContainer = styled.div`
  max-width: 531px;
  margin: 0 auto;

  font-size: 12px;
  line-height: 1.4;
  margin-top: 32px;

  .bold-text {
    font-family: ${({ theme }) => theme.font.semiBold};
    color: ${({ theme }) => theme.color.new};
    cursor: pointer;
  }

  @media (max-width: 575.98px) {
    width: 270px;
  }
`;
