import BlueGenericButton from 'components/blue-generic-button/BlueGenericButton';
import LoadingDocument from 'components/loading-cocument/LoadingDocument';
import SmallLoading from 'components/small-loading/SmallLoading';
import useWindowSize from 'hooks/useWindowSize';
import SignatureMethods from 'model/enums/signature-methods';
import { useEffect, useRef, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { RootState } from 'redux/storeConfig';
import contractService from 'services/contract-service';
import { ReactComponent as DownloadIconSvg } from '../../assets/images/audit-sign/download-icon.svg';
import { ReactComponent as PrintIconSvg } from '../../assets/images/audit-sign/print-icon.svg';
import {
  StyledButton,
  StyledContainer,
  StyledContractContainer,
  StyledFooter,
  StyledHeader,
  StyledHeaderContainer,
  StyledLink,
  StyledLoadingContainer,
} from './styles';
import useUrlToken from 'hooks/useUrlToken';

const ViewContractToSign = ({ t }: WithTranslation) => {
  const navigate = useNavigate();
  const documentRef = useRef(null);
  const [width] = useWindowSize();

  const { contract } = useSelector((state: RootState) => state);

  const [totalPages, setTotalPages] = useState<number>(0);
  const [scale, setScale] = useState<number>(0.6);
  const [pageSize, setPageSize] = useState<string>('100mm 135mm');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { hasUrlToken } = useUrlToken();

  useEffect(() => {
    if (width > 768) {
      setScale(1.6);
      setPageSize('240mm 355mm');
    }
  }, [width]);

  const handleToSign = async () => {
    setIsLoading(true);

    if (contract.contracDetails?.subscriptionKey != null && contract.contracDetails?.signatureMethods?.includes(SignatureMethods.TOKEN)) {
      if (!hasUrlToken()) {
        await contractService.sendTokenToEmail(contract.contracDetails?.subscriptionKey);
      }
    }

    setIsLoading(false);
    navigate('/etapas-assinatura');
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };

  const handlePrint = useReactToPrint({
    content: () => documentRef.current,
    pageStyle: `
        @page {
            size: ${pageSize};
        }
    `,
    documentTitle: contract.contracDetails?.document?.name,
  });

  return (
    <StyledContainer>
      {contract.contracDetails?.document?.company.logo != null ? (
        <>
          <StyledHeaderContainer>
            <StyledHeader>
              <img
                src={`data:image/png;base64, ${contract.contracDetails?.document.company.logo}`}
                alt="Logo"
                style={{ maxHeight: '55px' }}
              />

              <div>
                <StyledLink
                  download={`${contract.contracDetails?.document.name}`}
                  href={`data:${contract.contracDetails?.document.originalDocument?.contentType};base64,${contract.contracDetails?.documentBase64}`}
                  isDisabled={contract.contracDetails?.documentBase64 == null}
                >
                  <DownloadIconSvg />
                </StyledLink>
                <StyledButton onClick={handlePrint} disabled={contract.contracDetails?.documentBase64 == null}>
                  <PrintIconSvg />
                </StyledButton>
              </div>
            </StyledHeader>
          </StyledHeaderContainer>

          <StyledContractContainer>
            <Document
              file={`data:${contract.contracDetails?.document.originalDocument?.contentType};base64,${contract.contracDetails?.documentBase64}`}
              loading={<LoadingDocument />}
              onLoadSuccess={onDocumentLoadSuccess}
              ref={documentRef}
            >
              {Array.apply(null, new Array(totalPages))
                .map((x, i) => i + 1)
                .map(page => (
                  <Page key={`page-${page}`} pageNumber={page} scale={scale} loading="" />
                ))}
            </Document>
          </StyledContractContainer>

          <StyledFooter>
            <BlueGenericButton
              disabled={contract.contracDetails?.documentBase64 == null || isLoading}
              isLoading={contract.contracDetails?.documentBase64 == null || isLoading}
              onClick={() => handleToSign()}
            >
              {t('global.button.toSign')}
            </BlueGenericButton>
          </StyledFooter>
        </>
      ) : (
        <StyledLoadingContainer>
          <SmallLoading />
        </StyledLoadingContainer>
      )}
    </StyledContainer>
  );
};

export default withTranslation()(ViewContractToSign);
