import BlueGenericButton from 'components/blue-generic-button/BlueGenericButton';
import CardShowContract from 'components/card-show-contract/CardShowContract';
import InstructionCard from 'components/instruction-card/InstructionCard';
import PageTitleSection from 'components/page-title-section/PageTitleSection';
import AuthenticationTypes from 'model/enums/authentication-type';
import SignatureMethods from 'model/enums/signature-methods';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/storeConfig';
import networkDataService from 'services/network-data-service';
import { ReactComponent as ConfirmDataIconSvg } from '../../assets/images/audit-sign/blue-confirm-data-icon.svg';
import { ReactComponent as StartIconSvg } from '../../assets/images/audit-sign/blue-start-icon.svg';
import { ReactComponent as CameraIconSvg } from '../../assets/images/audit-sign/camera-icon.svg';
import { ReactComponent as HandwrittenSignatureIconSvg } from '../../assets/images/audit-sign/handwritten-signature-icon.svg';
import { StyledCardsContainer, StyledCardShowContractContainer, StyledContainer, StyledInnerContainer } from './styles';
import SubscriptionInformationCard from 'components/subscription-information-card/SubscriptionInformationCard';
import { addGeolocationCoordinates, addInternetProtocol } from 'redux/slice/location-data';

enum GeolocationErrorType {
  PERMISSION_DENIED = 1,
  POSITION_UNAVAILABLE = 2,
  TIMEOUT = 3,
}

type TypeSignatureStep = {
  [key in SignatureMethods]: JSX.Element;
};

const LetsSign = ({ t }: WithTranslation) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { contract, location } = useSelector((state: RootState) => state);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [geolocationData, setGeolocationData] = useState<GeolocationCoordinates>();

  const [navigationStart, setNavigationStart] = useState(window.performance.timing.navigationStart);

  useEffect(() => {
    setGeolocationData(JSON.parse(location?.geolocationData ?? '{}'));
  }, [location.geolocationData]);

  useEffect(() => {
    networkDataService
      .getInternetProtocol()
      .then(reponse => dispatch(addInternetProtocol(reponse)))
      .catch(() => setErrorMessage(t('global.errorMessage.validatingData')));
  }, []);

  useEffect(() => {
    const handleBeforeUnload = e => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    const handlePageRefresh = () => {
      if (performance.navigation.type === 1) {
        navigate(-1);
      }
    };

    window.addEventListener('load', handlePageRefresh);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('load', handlePageRefresh);
    };
  }, [navigate]);

  if ('geolocation' in navigator) {
    const options = {
      enableHighAccuracy: true,
      maximumAge: 0,
    };

    const success = ({ coords }: GeolocationPosition) => {
      dispatch(
        addGeolocationCoordinates(
          JSON.stringify({
            latitude: coords.latitude,
            longitude: coords.longitude,
          })
        )
      );
    };

    const error = async ({ code }: GeolocationPositionError) => {
      if (code === GeolocationErrorType.PERMISSION_DENIED) {
        setErrorMessage(t('global.errorMessage.enableGeolocation'));
      } else {
        setErrorMessage(t('global.errorMessage.validatingData'));
      }
    };

    navigator.geolocation.getCurrentPosition(success, error, options);
  } else {
    setErrorMessage(t('global.errorMessage.browserNoSupportGeolocation'));
  }

  const cardsFromSignatureSteps = (signatureMethod: SignatureMethods) => {
    const steps: TypeSignatureStep = {
      [SignatureMethods.DATA_CONFIRMATION]: (
        <InstructionCard
          icon={<ConfirmDataIconSvg />}
          title={t('letsSign.confirmData')}
          text={t('letsSign.personalData')}
          key={SignatureMethods.DATA_CONFIRMATION}
        />
      ),
      [SignatureMethods.TOKEN]: (
        <InstructionCard
          icon={<StartIconSvg />}
          title={t('letsSign.enterToken')}
          text={
            contract.contracDetails?.authentication === AuthenticationTypes.SMS ? t('letsSign.sentYourPhone') : t('letsSign.sentYourEmail')
          }
          key={SignatureMethods.TOKEN}
        />
      ),
      [SignatureMethods.SELFIE]: (
        <InstructionCard
          icon={<CameraIconSvg />}
          title={t('letsSign.validationSelfie')}
          text={t('letsSign.confirmationSelfie')}
          key={SignatureMethods.SELFIE}
        />
      ),
      [SignatureMethods.SIGNATURE]: (
        <InstructionCard
          icon={<HandwrittenSignatureIconSvg />}
          title={t('letsSign.handwrittenSignature')}
          text={t('letsSign.signatureMobileDevice')}
          key={SignatureMethods.SIGNATURE}
        />
      ),
    };

    return steps[signatureMethod];
  };

  return (
    <StyledContainer>
      <PageTitleSection i18nKeyTitle="letsSign.title" i18nKeySubtitle="letsSign.subtitle" />

      <StyledInnerContainer>
        <StyledCardsContainer>
          {contract.contracDetails?.signatureMethods?.map(signatureMethod => cardsFromSignatureSteps(signatureMethod))}
          <BlueGenericButton
            onClick={() => navigate('/confirmar-dados')}
            errorMessage={errorMessage}
            disabled={geolocationData?.latitude == null || geolocationData?.longitude == null || location?.internetProtocol == null}
            isLoading={geolocationData?.latitude == null || geolocationData?.longitude == null || location?.internetProtocol == null}
            style={{ marginTop: '16px' }}
          >
            {t('global.button.nextStep')}
          </BlueGenericButton>
        </StyledCardsContainer>

        <StyledCardShowContractContainer>
          {contract.contracDetails?.batchSignatureData != null ? <SubscriptionInformationCard /> : <CardShowContract />}
        </StyledCardShowContractContainer>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default withTranslation()(LetsSign);
