import React from 'react';
import { SmallLoadingWrapper } from './styles';

class SmallLoading extends React.Component {
  render() {
    return (
      <SmallLoadingWrapper>
        <div className="small-loading-wrap" />
      </SmallLoadingWrapper>
    );
  }
}

export default SmallLoading;
