import BoxHeaderAndFooter from 'components/box-header-and-footer/BoxHeaderAndFooter';
import UseCustomStyleContext from 'context/custom-style';
import ConfirmDetails from 'features/confirm-details/ConfirmDetails';
import ContractSignatures from 'features/contract-signatures/ContractSignatures';
import CreatePassword from 'features/create-password';
import Dashboard from 'features/dashboard/Dashboard';
import DownloadSignedDocuments from 'features/download-signed-documents/DownloadSignedDocuments';
import ForgotPassoword from 'features/forgot-password';
import GoToMobile from 'features/go-to-mobile/GoToMobile';
import InsertToken from 'features/insert-token/InsertToken';
import InvalidSubscriptionKey from 'features/invalid-subscription-key/InvalidSubscriptionKey';
import LetsSign from 'features/lets-sign/LetsSign';
import Logout from 'features/logout/logout';
import NewPassword from 'features/new-password';
import PasswordChanged from 'features/password-changed';
import PasswordCreated from 'features/password-created';
import PendingSubscriptions from 'features/pending-subscriptions/PendingSubscriptions';
import RegistrationConfirmed from 'features/registration-confirmed';
import SignIn from 'features/sign-in';
import Signature from 'features/signature/Signature';
import SubscriptionCompleted from 'features/subscription-completed/SubscriptionCompleted';
import SubscriptionHistory from 'features/subscription-history/SubscriptionHistory';
import TakeSelfie from 'features/take-selfie/TakeSelfie';
import TakesContractAndRedirectViewContract from 'features/takes-contract-and-redirect-view-contract';
import VerifyEmail from 'features/verify-email';
import ViewContractToSign from 'features/view-contract-to-sign/ViewContractToSign';
import ViewMultipleContractsToSign from 'features/view-multiple-contracts-to-sign';
import { useContext } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { DefaultTheme, ThemeProvider } from 'styled-components';

const PrivateRoute = ({ children }) => (true ? children : <Navigate to="/" />);

const AppRoutes = () => {
  const { colorPrimary, colorSecundary } = useContext(UseCustomStyleContext);

  const customTheme = (defaultTheme: DefaultTheme) => {
    defaultTheme.color.colorPrimary = colorPrimary;
    defaultTheme.color.colorSecundary = colorSecundary;

    return defaultTheme;
  };

  return (
    <ThemeProvider theme={customTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/assinar/:subscriptionKey" element={<TakesContractAndRedirectViewContract />} />
          <Route path="/documento" element={<ViewContractToSign />} />
          <Route path="/documentos" element={<BoxHeaderAndFooter children={<ViewMultipleContractsToSign />} />} />
          <Route path="/qr-code/:subscriptionKey" element={<BoxHeaderAndFooter children={<GoToMobile />} />} />
          <Route path="/etapas-assinatura" element={<BoxHeaderAndFooter children={<LetsSign />} />} />
          <Route path="/confirmar-dados" element={<BoxHeaderAndFooter children={<ConfirmDetails />} />} />
          <Route path="/token" element={<BoxHeaderAndFooter children={<InsertToken />} />} />
          <Route path="/selfie" element={<BoxHeaderAndFooter children={<TakeSelfie />} />} />
          <Route path="/assinatura" element={<BoxHeaderAndFooter children={<Signature />} />} />
          <Route path="/assinatura-finalizada" element={<BoxHeaderAndFooter children={<SubscriptionCompleted />} />} />
          <Route path="/" element={<SignIn />} />
          <Route path="/sair" element={<Logout />} />
          <Route path="/criar-senha/:key" element={<CreatePassword />} />
          <Route path="/senha-criada" element={<PasswordCreated />} />
          <Route path="/esqueceu-a-senha" element={<ForgotPassoword />} />
          <Route path="/confira-o-email" element={<VerifyEmail />} />
          <Route path="/criar-nova-senha/:key" element={<NewPassword />} />
          <Route path="/senha-alterada" element={<PasswordChanged />} />
          <Route path="/cadastro-confirmado" element={<RegistrationConfirmed />} />
          <Route path="/documentos/lote/:uuid" element={<BoxHeaderAndFooter children={<DownloadSignedDocuments />} />} />

          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <BoxHeaderAndFooter children={<Dashboard />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/assinaturas-pendentes"
            element={
              <PrivateRoute>
                <BoxHeaderAndFooter children={<PendingSubscriptions />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/historico-assinaturas"
            element={
              <PrivateRoute>
                <BoxHeaderAndFooter children={<SubscriptionHistory />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/contrato"
            element={
              <PrivateRoute>
                <BoxHeaderAndFooter children={<ContractSignatures />} />
              </PrivateRoute>
            }
          />

          <Route path="*" element={<BoxHeaderAndFooter children={<InvalidSubscriptionKey />} />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default AppRoutes;
