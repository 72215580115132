import { rtkApi } from 'api/rtkApi';
import { Authentication } from 'model/authentication';
import { User } from 'model/user';
import { setAccount } from 'redux/slice/authentication';
import AuthUtils from 'shared/util/auth-utils';

export const authenticationApi = rtkApi.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation<Authentication, Authentication>({
      query: payload => ({
        body: payload,
        method: 'POST',
        url: '/authenticate/person',
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const query = await queryFulfilled;
        const authentication = query.data;
        if (authentication?.token) {
          AuthUtils.setToken(authentication.token);
        }
      },
    }),
    getSession: builder.query<User, void>({
      query: payload => ({
        body: payload,
        method: 'GET',
        url: '/account',
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const query = await queryFulfilled;
        const account = query.data;
        if (account) {
          AuthUtils.setAccount(account);
          dispatch(setAccount(account));
        }
      },
    }),
  }),
});

export const { useLoginMutation, useLazyGetSessionQuery } = authenticationApi;
