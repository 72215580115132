import styled from 'styled-components/macro';
export const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 992px;
  margin: 0 auto;
`;

export const StyledTableCotainer = styled.div`
  margin: 24px 0;
`;

export const StyledFiltersContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: baseline;
  column-gap: 10px;
  margin-bottom: 15px;
`;
