import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Snackbar } from '@mui/material';
import { useForgotPasswordMutation } from 'api/endpoints/user';
import GenericSignIn from 'components/generic-signIn';
import useRequestErrorHandler from 'hooks/useRequestErrorHandler';
import _ from 'lodash';
import { InputNames } from 'model/enums/input-names';
import { InputTypes } from 'model/enums/input-types';
import { RoutePath } from 'model/enums/routes-path';
import { GenericFormData } from 'model/generic-form-data';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import StringUtils, { validateCpf } from 'shared/util/string-utils';
import * as yup from 'yup';

interface ForgotPassowordProps {}
const ForgotPassoword: React.FC<ForgotPassowordProps> = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<GenericFormData>();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const navigate = useNavigate();
  const { openAlert, errorMessage, makeRequestWithErrorHandler } = useRequestErrorHandler();

  const subtitles = [t('signFlow.forgotPassword.subtitle')];
  const inputList = [
    {
      label: t('confirmDetails.individualRegistration'),
      name: InputNames.CPF,
      type: InputTypes.TEXT,
      placeholder: t('global.field.placeholders.cpf'),
      formData: formData,
      setFormData: setFormData,
      mask: StringUtils.cpfMask,
      maxLength: 14,
    },
  ];

  const handleForm = async () => {
    const isValid = await methods.trigger();
    if (!isValid) {
      return;
    }

    makeRequestWithErrorHandler(async () => {
      const forgotPasswordData = {
        cpf: StringUtils.removeMask(formData?.cpf ?? ''),
      };

      const data = await forgotPassword(forgotPasswordData).unwrap();
      navigate(RoutePath.VERIFY_EMAIL, {
        state: {
          cpf: StringUtils.removeMask(formData?.cpf ?? ''),
          email: data.email,
        },
      });
    });
  };

  const schema = yup.object().shape({
    [InputNames.CPF]: yup
      .string()
      .test(`test-cpf`, t('global.errorMessage.invalidCpf'), numbers => validateCpf(numbers!))
      .required(t('global.field.validations.required')),
  });

  const methods = useForm({ resolver: yupResolver(schema), mode: 'onSubmit' });

  const handleSubmit = _.debounce(handleForm, 500);

  return (
    <>
      <Snackbar open={openAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <GenericSignIn
        title={t('signFlow.forgotPassword.title')}
        subtitles={subtitles}
        inputList={inputList}
        buttonText={t('signFlow.forgotPassword.buttonText')}
        handleSubmit={handleSubmit}
        methods={methods}
        formData={formData}
        setFormData={setFormData}
        isLoading={isLoading}
      />
    </>
  );
};

export default ForgotPassoword;
