import { Alert, Snackbar } from '@mui/material';
import { useForgotPasswordMutation } from 'api/endpoints/user';
import GenericSignIn from 'components/generic-signIn';
import useRequestErrorHandler from 'hooks/useRequestErrorHandler';
import { RoutePath } from 'model/enums/routes-path';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

interface State {
  cpf?: string;
  email?: string;
}

interface VerifyEmailProps {}

const VerifyEmail: React.FC<VerifyEmailProps> = props => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locationState = useLocation().state as State;
  const cpf = locationState?.cpf;
  const email = locationState?.email;
  const [forgotPassword] = useForgotPasswordMutation();

  const { openAlert, errorMessage, makeRequestWithErrorHandler } = useRequestErrorHandler();

  useEffect(() => {
    if (!cpf || !email) {
      return navigate(-1);
    }
  }, [cpf, email]);

  const subtitles = [`${t('signFlow.verifyEmail.subtitle1')} ${email}`, t('signFlow.verifyEmail.subtitle2')];
  const inputList = [];

  const resendEmail = async () => {
    makeRequestWithErrorHandler(async () => {
      const forgotPasswordData = {
        cpf: cpf ?? '',
      };
      await forgotPassword(forgotPasswordData).unwrap();
    });
  };

  return (
    <>
      <Snackbar open={openAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <GenericSignIn
        title={t('signFlow.verifyEmail.title')}
        subtitles={subtitles}
        inputList={inputList}
        onClick={() => navigate(RoutePath.SIGNIN)}
        resendEmail={resendEmail}
      />
    </>
  );
};

export default VerifyEmail;
