import GenericSignIn from 'components/generic-signIn';
import { RoutePath } from 'model/enums/routes-path';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface RegistrationConfirmedProps {}
const RegistrationConfirmed: React.FC<RegistrationConfirmedProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const subtitles = [t('signFlow.registrationConfirmed.subtitle')];
  const inputList = [];

  return (
    <GenericSignIn
      title={t('signFlow.registrationConfirmed.title')}
      subtitles={subtitles}
      inputList={inputList}
      buttonText={t('signFlow.registrationConfirmed.buttonText')}
      onClick={() => navigate(RoutePath.SIGNIN)}
    />
  );
};

export default RegistrationConfirmed;
