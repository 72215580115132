import styled from 'styled-components/macro';
export const StyledIconContainer = styled.div`
  text-align: right;
  margin: 16px;
  cursor: pointer;
`;

export const StyledContainer = styled.main`
  max-width: 480px;
  margin: 0 auto;

  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray};
`;

export const StyledTitle = styled.div`
  margin: 12px;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
`;

export const StyledButtonContainer = styled.div`
  width: 180px;
  margin: 30px auto 0 auto;
`;
