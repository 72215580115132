import companyApi from 'api/company-api';
import { AxiosError, AxiosResponse } from 'axios';
import ICompany from 'model/company';
import { ISubscriptionData } from 'model/contract-details';
import IDocument, { ITypeContractsFilter } from 'model/document';
import SignatureMethods from 'model/enums/signature-methods';
import { PageableResponse, PaginationParameters } from 'model/pageable';
import { TypeBatchSubscription } from 'model/type-batch-subscription';

export const companyService = () => {
  const getAllCompanies = async () => {
    try {
      const result: AxiosResponse<ICompany[]> = await companyApi.getAllCompanies();
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAllDocumentsPendingSignature = async (uuid: string, pagination: PaginationParameters) => {
    try {
      const result: AxiosResponse<PageableResponse<IDocument>> = await companyApi.getAllDocumentsPendingSignature(uuid, pagination);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getDocumentSignatureHistory = async (uuid: string, pagination: PaginationParameters) => {
    try {
      const result: AxiosResponse<PageableResponse<IDocument>> = await companyApi.getDocumentSignatureHistory(uuid, pagination);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const sendBatchSignatureTokenToEmail = async (subscriptionData: TypeBatchSubscription) => {
    try {
      const result: AxiosResponse<SignatureMethods[]> = await companyApi.sendBatchSignatureTokenToEmail(subscriptionData);
      if (result.status === 201 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const signBatchContract = async (subscriptionData: ISubscriptionData) => {
    try {
      const result: AxiosResponse<ISubscriptionData> = await companyApi.signBatchContract(subscriptionData);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getAllCompanies,
    getAllDocumentsPendingSignature,
    getDocumentSignatureHistory,
    sendBatchSignatureTokenToEmail,
    signBatchContract,
  };
};

export default companyService();
