import React from 'react';
import './App.css';
import './i18n/yup-translate';
import AppRoutes from './shared/routes/routes';

interface IAppState {
  isLoading: boolean;
}

class App extends React.Component<IAppState> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  isLoading = (isLoading: boolean) => {
    this.setState({
      isLoading,
    });
  };

  render() {
    return (
      <div className={'page-container'}>
        <AppRoutes />
      </div>
    );
  }
}

export default App as React.ComponentType<any>;
