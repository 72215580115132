export default {
  color: {
    primaryColor: '#0F62FE',
    red: '#E40B21',
    gray: '#393939',
    gray2: '#5c5c5c',
    gray3: '#f4f4f4',
    backgroundGray: '#E0E0E0',
    colorPrimary: '#0F62FE',
    colorSecundary: '#78A9FF',
    waiting_approval_to_validate: '#F6B330',
    valid: '#24A148',
    finished: '#24A148',
    lightGray1: '#EEEEEE',
    lightGray2: '#E5E7E7',
    gray18: '#7F7F7F',
    gray19: '#4C4D4D',
    gray20: '#E5E7E7',
    gray21: '#333333',
    gray22: '#656666',
    gray23: '#CCCCCC',
    gray24: '#999999',
    black0: '#000',
    black1: '#010101',
    black1b: '#1B1A1B',
    pink: '#E40B53',
    orange: '#E42F0B',
    grayBackground: '#FAFAFA',
    new: '#6585E8',
    in_progress: '#F7B330',
    contract: '#0DB052',
    payment: '#0DB052',
    rejected: '#FC4F4F',
    canceled: '#CB0101',
    approved: '#0DB052',
    adjusted: '#0DB052',
    validation: '#F7B330',
    cancelled: '#CB0101',
    adjust: '#F6B330',
    white: '#FFFF',
    shadow: 'rgba(0, 0, 0, 0.06)',
    borderGray: '#EEEEEE',
    statusNew: '#6585E8',
    verylightBlue: '#EFF6FF',
    green: '#0DB052',
    lightBlue: '#EDF1FF',
    lightRed: '#FFF4F2',
    gray50: '#8d8d8d',
  },
  font: {
    regular: 'OpenSans-Regular',
    medium: 'OpenSans-Medium',
    semiBold: 'OpenSans-SemiBold',
    bold: 'OpenSans-Bold',
    extraBold: 'OpenSans-ExtraBold',
  },
};
