import BlueGenericButton from 'components/blue-generic-button/BlueGenericButton';
import CardShowContract from 'components/card-show-contract/CardShowContract';
import PageTitleSection from 'components/page-title-section/PageTitleSection';
import useHelperNavigation, { routeNextStepSignature } from 'hooks/useHelperNavigation';
import { isEmpty } from 'lodash';
import { ISubscriptionData } from 'model/contract-details';
import SignatureMethods from 'model/enums/signature-methods';
import { useState } from 'react';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/storeConfig';
import { ReactComponent as CameraIconSvg } from '../../assets/images/audit-sign/camera-icon-without-background.svg';
import { ReactComponent as CellPhoneSvg } from '../../assets/images/audit-sign/cell-phone.svg';
import { addSignatureData } from '../../redux/slice/data-signing-contract';
import {
  StyledButtonsContainer,
  StyledCardShowContractContainer,
  StyledContainer,
  StyledInnerContainer,
  StyledInstructions,
  StyledInstructionsContainer,
  StyledList,
  StyledPhotoPreview,
  StyledSubtitle,
} from './styles';

const TakeSelfie = ({ t }: WithTranslation) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { contract, signature } = useSelector((state: RootState) => state);
  const { isLoadingSendingContractSignature, sendSignature } = useHelperNavigation();

  const [photo, setPhoto] = useState<string>('');
  const [isShowCamera, setIsShowCamera] = useState<boolean>(false);

  const handleTakingPhoto = (base64Photo: string) => {
    setPhoto(base64Photo);
    setIsShowCamera(false);
  };

  const handleConfirmPhoto = () => {
    const nextStep = routeNextStepSignature(SignatureMethods.SELFIE, contract.contracDetails?.signatureMethods);
    const subscriptionData: ISubscriptionData = {
      personSignature: {
        lat: signature.subscriptionData?.personSignature?.lat,
        lng: signature.subscriptionData?.personSignature?.lng,
        ip: signature.subscriptionData?.personSignature?.ip,
        name: signature.subscriptionData?.personSignature?.name,
        birth: signature.subscriptionData?.personSignature?.birth,
        signature: signature.subscriptionData?.personSignature?.signature,
        selfie: { file: photo.split(',')[1] },
      },
    };

    dispatch(addSignatureData(subscriptionData));

    if (nextStep != null) {
      navigate(nextStep);
    } else {
      const isEnvelopeSignature = contract.contracDetails?.envelope != null;
      const { personSignature, ...rest } = signature.subscriptionData || {};
      const updatedSubscriptionData: ISubscriptionData = {
        ...rest,
        personSignature: subscriptionData.personSignature,
      };

      sendSignature(updatedSubscriptionData, isEnvelopeSignature);
    }
  };

  return (
    <StyledContainer>
      <PageTitleSection
        signatureMethods={contract.contracDetails?.signatureMethods}
        currentSignatureMethod={SignatureMethods.SELFIE}
        i18nKeyTitle="takeSelfie.title"
        textAlign="left"
      />
      <StyledInnerContainer>
        {isShowCamera ? (
          <Camera onTakePhotoAnimationDone={handleTakingPhoto} idealResolution={{ width: 480, height: 480 }} />
        ) : isEmpty(photo) ? (
          <>
            <StyledSubtitle>{t('takeSelfie.shippingInstructions')}</StyledSubtitle>
            <StyledInstructionsContainer>
              <StyledInstructions>
                <div>
                  <CellPhoneSvg />
                </div>
                <StyledList>
                  <li>{t('takeSelfie.takeSelfieCentered')}</li>
                  <li>{t('takeSelfie.provideEnoughLighting')}</li>
                  <li>{t('takeSelfie.eyesOpen')}</li>
                  <li>{t('takeSelfie.avoidStrongShadows')}</li>
                  <li>{t('takeSelfie.doNotUseAccessories')}</li>
                  <li>{t('takeSelfie.makeSureSelfieBlurryAndShaky')}</li>
                </StyledList>
              </StyledInstructions>

              <BlueGenericButton style={{ marginTop: '25px' }} onClick={() => setIsShowCamera(true)}>
                <CameraIconSvg style={{ marginRight: '8px' }} />
                {t('global.button.takeSelfie')}
              </BlueGenericButton>
            </StyledInstructionsContainer>
          </>
        ) : (
          <>
            <StyledPhotoPreview src={photo} />
            <StyledButtonsContainer>
              <BlueGenericButton
                invertButtonColor
                onClick={() => {
                  setPhoto('');
                  setIsShowCamera(true);
                }}
              >
                {t('global.button.takeNewSelfie')}
              </BlueGenericButton>
              <BlueGenericButton isLoading={isLoadingSendingContractSignature} onClick={() => handleConfirmPhoto()}>
                {t('global.button.confirm')}
              </BlueGenericButton>
            </StyledButtonsContainer>
          </>
        )}
        <StyledCardShowContractContainer>
          <CardShowContract />
        </StyledCardShowContractContainer>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default withTranslation()(TakeSelfie);
