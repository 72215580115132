import PageTitleSection from 'components/page-title-section/PageTitleSection';
import { ReactComponent as SubscriptionCompletedSvg } from '../../assets/images/audit-sign/subscription-completed.svg';
import { StyledButtonContainer, StyledContainer, StyledSvgContainer } from './styles';
import { useNavigate } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import BlueGenericButton from 'components/blue-generic-button/BlueGenericButton';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/storeConfig';

const SubscriptionCompleted = ({ t }: WithTranslation) => {
  const { contract } = useSelector((state: RootState) => state);
  const navigate = useNavigate();

  return (
    <StyledContainer>
      <PageTitleSection
        i18nKeyTitle="subscriptionCompleted.title"
        i18nKeySubtitle="subscriptionCompleted.subtitle"
        style={{ justifyContent: 'center' }}
      />

      <StyledSvgContainer>
        <SubscriptionCompletedSvg />
      </StyledSvgContainer>

      {contract.contracDetails?.batchSignatureData != null && (
        <StyledButtonContainer>
          <BlueGenericButton onClick={() => navigate('/dashboard')}>{t('global.button.dashboard')}</BlueGenericButton>
        </StyledButtonContainer>
      )}
    </StyledContainer>
  );
};

export default withTranslation()(SubscriptionCompleted);
