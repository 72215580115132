import GenericSignIn from 'components/generic-signIn';
import { RoutePath } from 'model/enums/routes-path';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface PasswordChangedProps {}
const PasswordChanged: React.FC<PasswordChangedProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const subtitles = [t('signFlow.passwordChanged.subtitle')];
  const inputList = [];

  return (
    <GenericSignIn
      title={t('signFlow.passwordChanged.title')}
      subtitles={subtitles}
      inputList={inputList}
      buttonText={t('signFlow.passwordChanged.buttonText')}
      onClick={() => navigate(RoutePath.SIGNIN)}
    />
  );
};

export default PasswordChanged;
